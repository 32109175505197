import { useSpring, animated } from 'react-spring'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { resolveTileLink } from '/machinery/link'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'
import { Tile } from '/features/buildingBlocks/tile/Tile'

import mediaStyles from '/cssGlobal/media.css'
import styles from './RelatedContentGrid.css'

export function RelatedContentGridArticle({ tiles, availableStyleContexts = undefined }) {
  return (
    <RelatedContentGridBase className={styles.componentArticle} {... { tiles, availableStyleContexts }} />
  )
}

export function RelatedContentGridHome({ tiles, availableStyleContexts }) {
  return (
    <RelatedContentGridBase className={styles.componentHome} {... { tiles, availableStyleContexts }} />
  )
}

export function RelatedContentGridHero({ tiles, availableStyleContexts = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const selectedTiles = isViewportMd ? tiles : tiles.slice(0, 3)

  return <RelatedContentGridBase className={styles.componentHero} tiles={selectedTiles} {...{ availableStyleContexts }} />
}

function RelatedContentGridBase({ tiles, availableStyleContexts, className }) {
  const language = useLanguage()

  return (
    <span className={styles.componentBase}>
      <ul className={cx(styles.list, className)}>
        {tiles.filter(x => x.representation).map((x, i) => (
          <li data-context-tile-index={i} key={[i, x._id].join()}>
            <Tile
              representation={x.representation}
              href={resolveTileLink({ tile: x, language })}
              {...{ availableStyleContexts }}
            />
          </li>
        ))}
      </ul>
    </span>
  )
}

export function RelatedContentGridFooter({ tiles }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm)
  const extraTiles = tiles.slice(0,
    isViewportLg ? 14 :
    isViewportSm ? 10 :
    7
  )

  return (
    <ul className={styles.componentFooter}>
      <GridFooterTile id={0} />
      {extraTiles.filter(x => x.representation).map((tile, i) => (
        <GridFooterTile key={i} id={i} {...{ tile }} />
      ))}
    </ul>
  )
}

function GridFooterTile({ id, tile = undefined }) {
  const language = useLanguage()
  const { ref, wasInViewport } = useWasInViewport({ rootMargin: '-10%' })
  const { representation } = tile || {}
  const gridArea = tile ? `tile-${id + 1}` : 'amp'
  const { __ } = useTranslate()

  const style = useSpring({
    delay: 25 * id,
    opacity: wasInViewport ? 1 : 0,
    scale: wasInViewport ? 1 : 0.4,
    config: { mass: 0.5, tension: 600, friction: 50, precision: 0.01, velocity: 0 },
  })

  return (
    <animated.li style={{ gridArea, ...style }} className={cx(styles.componentGridFooterTile, !tile && styles.skillsMatchButton)} {...{ ref }}>
      {tile
        ? <Tile href={resolveTileLink({ tile, language })} {...{ representation }} />
        : <ShowSkillsMatchPortalFlowButton label={__`my-skills`} buttonType='block' layoutClassName={styles.skillsMatchButtonLayout} dataX='link-to-skillsmatchportal' />
      }
    </animated.li>
  )
}
