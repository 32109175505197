import { useShowSkillsMatchPortalFlow } from '/features/pageOnly/skillsMatch/skillsMatchPortalFlowHooks'
import { ButtonPrimary, ButtonSecondary } from '/features/buildingBlocks/Button'

/**
  * @param {{ label: string, buttonType: 'primary' | 'secondary' | 'block', dataX: string, layoutClassName?: string }} props
*/

export function ShowSkillsMatchPortalFlowButton({ label, buttonType, dataX, layoutClassName = undefined }) {
  const showSkillsMatchPortalFlow = useShowSkillsMatchPortalFlow()
  const ButtonComponent = getButtonComponent({ buttonType })

  return <ButtonComponent onClick={showSkillsMatchPortalFlow} {...{ label, dataX, layoutClassName }} />
}

function ButtonBlock({ dataX, label, onClick, layoutClassName }) {
  return (
    <button
      type='button'
      className={layoutClassName}
      data-x={dataX}
      aria-label={label}
      {...{ onClick }}
    >
      {label}
    </button>
  )
}

function getButtonComponent({ buttonType }) {
  switch (buttonType) {
    case 'primary': return ButtonPrimary
    case 'secondary': return ButtonSecondary
    case 'block': return ButtonBlock
    default: throw new Error('Invalid button type passed in ShowSkillsMatchPortalFlowButton component. Use one of the following: \'primary\', \'secondary\' or \'block\'')
  }
}
